import React from 'react';
import ReactDom from 'react-dom';
import '../CSS/style.css'
import background1 from '../images/parallax/parallax-720p.jpg';
import background2 from '../images/parallax/parallax-1080p.jpg';
import background3 from '../images/parallax/parallax-1440p.jpg';
import background4 from '../images/parallax/parallax-2160p.jpg';

class Pagebody extends React.Component {

    state = { currentSrc: '' };
    
    onLoad = (event) => {
        this.setState({
            currentSrc: event.target.currentSrc
        });
        var bgID = document.getElementById("background-image-loader");
        document.querySelector("body").style.setProperty("--background-img", 'url("' + ReactDom.findDOMNode(bgID).src + '")');    
        
    }

    


    render() {
        return (
            <div>
                <img id="background-image-loader" src={background1} srcSet={`${background1} 1280w, ${background2} 1920w, ${background3} 2560w, ${background4} 3480w`}
                    onLoad={this.onLoad} sizes="100vw" style={{ display: "none" }} alt="" />
            </div>
        );
    }
}

export default Pagebody;
