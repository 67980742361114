import React, { useState, useEffect, lazy, Suspense } from 'react';

// Import the reCAPTCHA script
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//import profilePic from '../images/profile.jpg';
import profilePic from '../gif/programmer.gif';
import bdArmy from '../images/bdArmy.jpg';
import awardsPic from '../images/awards.jpg';
import emailIcon from '../images/icons/email.svg';
import linkedinIcon from '../images/icons/linkedin.svg';
import githubIcon from '../images/icons/github.svg';
import playStoreBadge from '../images/google-play-badge.png';
import appStoreBadge from '../images/app-store-badge.png';
import '../CSS/materialize.min.css'
import '../CSS/custom.min43a0.css'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import YouTubeVideo from './YouTubeVideo';

function MainBody() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [recaptchaToken, setRecaptchaToken] = useState('');
    const [recaptchaError, setRecaptchaError] = useState('');

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
        setRecaptchaError(''); // Clear the error message
    };



    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if recaptchaToken is available
        if (!recaptchaToken) {
            setRecaptchaError('reCAPTCHA verification failed. Please try again later.');
            return;
        }

        try {
            const response = await fetch('send-email.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ...formData, recaptchaToken })
            });

            if (response.ok) {
                const result = await response.text();

                // Show success toast
                toast.success('Form submitted successfully!', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000, // Auto close the toast after 3 seconds
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                // Reset the form fields and reinitialize the formData state
                setFormData({
                    name: '',
                    email: '',
                    message: ''
                });

                // Clear recaptcha token
                setRecaptchaToken('');

                console.log(result); // Display success message from PHP
            } else {
                console.error('Failed to send email.');
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    return (
        <main>
            <div id="info">
                <div className="container">
					<Suspense fallback={<div>Loading...</div>}>
						<img src={profilePic} alt="way2tushar" className="profile-pic" draggable="false" />
					</Suspense>
					
                    <p className="head-font-resize center white-text mg-btm-0">Md Nazmul Islam</p>
                    <div className="row center">
                        <h5 className="header col s12 light white-text">Unity3D Developer</h5>
                    </div>
                    <div className="row center">
                        <AnchorLink className="btn btn-outlined waves-effect waves-light" href="#portfolio">Take a look at my work</AnchorLink>
                        <AnchorLink className="btn btn-outlined waves-effect waves-light" href="#contact">Contact me</AnchorLink>
                    </div>
                </div>
            </div>
            <a className="anchor" id="about" href="/#"> </a>
            <div className="z-depth-5 content scrollspy" id="__about">
                <div className="container row">
                    <br />
                    <div className="col s12">
                        <p className="flow-text"><strong>Hi There!</strong></p>
                        <p className="flow-text">
                            My name is <b>Md Nazmul Islam</b> and I'd like to welcome you to my portfolio. I am a <b>Software Engineer</b> from Dhaka, Bangladesh, specialized in <b>Unity3D</b>. I'm quietly confident, naturally curious, and perpetually working on improving my chops one design problem at a time.
                                              I have done work in game development, mobile app creation, front-end/back-end web, and software development. If you are interested, you can view some of my favourite projects in my portfolio down below.
                  If you would like to build something together or simply want to have a chat? Reach out to me on social media or send me an email.<br />
                            Feel free to <a href="#contact">get in touch</a> or take a look at my
<a href="#portfolio">past work</a> below.
</p>
                    </div>

                </div>
            </div>
            <a className="anchor" id="portfolio" href="/#"> </a>
            <div className="banner main-banner white-text center">
                <br /><br />
                <div className="section">
                    <div className="container">
                        <h4 className="header col s12 light header-text">Portfolio</h4>
                    </div>
                </div>
                <br /><br />
            </div>
            <div className="z-depth-5 content scrollspy" id="__portfolio">
                <div className="container">
                    <br />

                    <br /><br /><br />
                    <div className="portfolio-timeline">
                        <div className="entry timeline-left" data-aos="fade">
                            <div className="content" data-aos="fade-right">
                                <h5>Bangladesh Army Interactive Communication Training and Tactical Laboratory</h5>
                                <a href="/#"><div className="embed-responsive embed-responsive-16by9">
                                    <img className="embed-responsive-item" title="way2tushar" width="100%" src={bdArmy}></img>
                                    </div></a>
                                    <p>We democratize technology for all, enabling customization to meet individual needs. Our recent project involved creating an augmented training module for the <b>Bangladeshi Army</b>, enhancing their effectiveness in both war and peacekeeping missions</p>
                                
                            </div>
                        </div>
                        
                            
                            <div className="entry" data-aos="fade">
                                <div className="content" data-aos="fade-left">
                                    <h5>Bino (AR Application)</h5>
                                <a><div className="embed-responsive embed-responsive-16by9">
									<Suspense fallback={<div>Loading...</div>}>
										<YouTubeVideo videoId="dR8TVHFgWLQ" />
									</Suspense>                                    
                                </div></a>
                                <p><b>Bino</b> is an extraordinary approach to modernize the primary education system through <b>Augmented reality</b>. Children will enjoy learning Alphabet with exciting 3D animated figures with this Bino Apps. They can play in their imaginary world. I'm Co-Developer of this Application.</p>
                                <ul className="actions special">
                                    <li>
                                        <a href="https://play.google.com/store/apps/details?id=com.microtech.neelimarbioscope&hl=en" target="_blank" rel="noreferrer" className="button"><img src={playStoreBadge} alt="" height="50"></img></a>
                                        <a href="https://apps.apple.com/us/app/bino/id1203209781" target="_blank" rel="noreferrer" className="button"><img src={appStoreBadge} alt="" height="50"></img></a>
                    </li>
                  </ul>
                              
                            </div>
                            </div>

                            <div className="entry timeline-left" data-aos="fade">
                            <div className="content" data-aos="fade-right">
                            <h5>Akadimia Ai (iOS App)</h5>
                                <a><div className="embed-responsive embed-responsive-16by9">
									<Suspense fallback={<div>Loading...</div>}>
										<YouTubeVideo videoId="drLnhIV6ni4" />
									</Suspense>
                                    </div></a>
                                    <p><b>Akadimia</b>  is a cutting-edge platform that lets you engage in spoken conversations with digital renditions of historical icons. Whether you're fascinated by scientists, explorers, entrepreneurs, or kings, you can now learn from their wisdom through the power of AI-driven dialogue. The app boasts three dynamic modes: 3D, Augmented Reality, and Chat. In 3D and AR modes, your voice becomes the bridge to the past as you speak and interact with these influential figures. Immerse yourself visually, integrate history with your surroundings, and engage in vocal exchanges to uncover the invaluable insights of these remarkable personalities. It's a revolutionary way to absorb history, made possible through the blend of technology and learning.</p>
                                <ul className="actions special">
                                    <li><a href="https://apps.apple.com/us/app/akadimia-ai/id1604169673" target="_blank" rel="noreferrer" className="button"><img src={appStoreBadge} alt="" height="50"></img></a></li>
                  </ul>
                                </div>
                        </div>
                        
                        
                            
                            <div className="entry" data-aos="fade">
                                <div className="content" data-aos="fade-left">
                                    <h5>Man vs Zombies (VR Game)</h5>
                                <a><div className="embed-responsive embed-responsive-16by9">
									<Suspense fallback={<div>Loading...</div>}>
										<YouTubeVideo videoId="2--QQRf6uFI" />
									</Suspense>
                                    </div></a>
                                    <p><b>Virtual reality</b> Game is completely new in bangladesh, Microtech interactive brings a brand new VR game called <b>Man Vs Zombies</b> where you can jump into the city where zombie is cralling, all you have to do is killiing them and sirvive. Our VR technology game will give you the real life and experience of this zombie game. I'm Leading Developer of this Game.</p>
                                </div>
                            </div>
                            

                            <div className="entry timeline-left" data-aos="fade">
                                <div className="content" data-aos="fade-right">
                                    <h5>Croco Run (Android and iOS Game)</h5>
                                <a><div className="embed-responsive embed-responsive-16by9">
									<Suspense fallback={<div>Loading...</div>}>
										<YouTubeVideo videoId="9Srus71AuyM" />
									</Suspense>
                                    </div></a>
                                    <p>Croco Run is the best running game. Dodge the cute crocodile from obstacles. Don't forget to eat the energy coin. The coin will give energy to the crocodile to give a boost and break the obstacles on the lake.
                                    The obstacles will reduce your life. Four hits will kill you.
                                    Be aware of the stones. They are deadly.
                                    Compete online with other Crocodile and be the best.
                  The Croco is very easy to control. Just right and left will take you to endless joy.</p>
                                    <ul className="actions special">
                                    <li>
                                        <a href="https://play.google.com/store/apps/details?id=com.MicrotechInteractive.CorcoRun" target="_blank" rel="noreferrer" className="button"><img src={playStoreBadge} alt="" height="50"></img></a>
                                        <a href="https://apps.apple.com/us/app/croco-run/id1551609048" target="_blank" rel="noreferrer" className="button"><img src={appStoreBadge} alt="" height="50"></img></a>
                    </li>
                  </ul>
</div>
</div>
                                    <div className="entry" data-aos="fade">
                                        <div className="content" data-aos="fade-left">
                                            <h5>3D Space Race (Android Game)</h5>
                                <a><div className="embed-responsive embed-responsive-16by9">
									<Suspense fallback={<div>Loading...</div>}>
										<YouTubeVideo videoId="91w12GJkMXo" />
									</Suspense>
                                    </div></a>
                                            <p>3D Space Race is 3D racing game made with Unity3D where the player needs to move the Spaceship. There are Two types of Control, one is Tilt and another is Button. This was my First game.</p>
                                <ul className="actions special">
                                    <li><a href="https://play.google.com/store/apps/details?id=com.MicrotechInteractive.spaceRace&hl=en" target="_blank" rel="noreferrer" className="button"><img src={playStoreBadge} alt="" height="50"></img></a></li>
                  </ul>
</div>
                                        </div>
                                        <div className="entry timeline-left" data-aos="fade">
                                            <div className="content" data-aos="fade-right">
                                                <h5>Crazy Cricket (Android Game)</h5>
                                <a><div className="embed-responsive embed-responsive-16by9">
									<Suspense fallback={<div>Loading...</div>}>
										<YouTubeVideo videoId="RNBymtgAA-k" />
									</Suspense>
                                    </div></a>
                                                <p>Cricket lover can now have awesome 2D mobile cricket game at the palm of their hands! You can shot as making a high score run.This is a game built for you the cricket fan! You can make a high score run and Challenge or Share with your Friends. Have Fun! I'm Co-Developer of this Application. </p>
                                <ul className="actions special">
                                    <li><a href="https://play.google.com/store/apps/details?id=com.microtechinteractive.crickethit6&hl=en" target="_blank" rel="noreferrer" className="button"><img src={playStoreBadge} alt="" height="50"></img></a></li>
                  </ul>
</div>
                                            </div>
                                            <div className="entry" data-aos="fade">
                                                <div className="content" data-aos="fade-left">
                                                    <h5>Rickshawala (Android Game)</h5>
                                <a><div className="embed-responsive embed-responsive-16by9">
									<Suspense fallback={<div>Loading...</div>}>
										<YouTubeVideo videoId="NM0RAKIkuwc" />
									</Suspense>
                                    </div></a>
                                                    <p>Rickshawala is 3D racing game made with Unity3D where the player needs to move the Rickshawa. Player can buy many types of Rickshawa. I'm Co-Developer of this Application.</p>
                                <ul className="actions special">
                                    <li><a href="https://play.google.com/store/apps/details?id=com.microtechinteractive.rickshawala&hl=en" target="_blank" rel="noreferrer" className="button"><img src={playStoreBadge} alt="" height="50"></img></a></li>
                  </ul>
</div>
                                                </div>
                                                <div className="entry timeline-left" data-aos="fade">
                                                    <div className="content" data-aos="fade-right">
                                                        <h5>Film Festival Event Website (Bootstrap)</h5>
                                <a><div className="embed-responsive embed-responsive-16by9">
									<Suspense fallback={<div>Loading...</div>}>
										<YouTubeVideo videoId="Dk-UTe8rsac" />
									</Suspense>
                                    </div></a>
                                                        <p>Liberation war museum: Film Festival Event's registration website.</p>
</div>
                                                </div>
                                                <div className="entry" data-aos="fade">
                                                    <div className="content" data-aos="fade-left">
                                                        <h5>Bino Stock Website (PHP)</h5>
                                <a><div className="embed-responsive embed-responsive-16by9">
									<Suspense fallback={<div>Loading...</div>}>
										<YouTubeVideo videoId="JzzCbo9Ek7E" />
									</Suspense>
                                    </div></a>
                                                        <p>Bino Stock is an inventory management website.</p>
                                                    </div>
                                                </div>
                                                <div className="entry timeline-left" data-aos="fade">
                                                    <div className="content" data-aos="fade-right">
                                                        <h5>Wireless Bot (Robotics Projects)</h5>
                                <a><div className="embed-responsive embed-responsive-16by9">
									<Suspense fallback={<div>Loading...</div>}>
										<YouTubeVideo videoId="w6xHK84dNi8" />
									</Suspense>
                                    </div></a>
                                                        <p>This Bot was made with <b>Raspberry pi</b>, mainly this bot made for playing with ball which controlled over wifi. This was my project which I made for my university's Inter Department competition.</p>
                                                    </div>
                                                </div>
                                                <div className="entry" data-aos="fade">
                                                    <div className="content" data-aos="fade-left">
                                                        <h5>Line Follower Robot (Robotics Projects)</h5>
                                <a><div className="embed-responsive embed-responsive-16by9">
									<Suspense fallback={<div>Loading...</div>}>
										<YouTubeVideo videoId="72i-eqQH7SM" />
									</Suspense>
                                    </div></a>
                                                        <p>This project was made by <b>Arduino</b>. This robot follow on black line.</p>
                                                    </div>
                                                </div>

                                            </div>

                                            <br />
                                            <br />
                                        </div>
                                    </div>
            <a className="anchor" id="work" href="/#"> </a>
                                    <div className="banner main-banner white-text center">
                                        <br /><br />
                                        <div className="section">
                                            <div className="container">
                                                <h4 className="header col s12 light header-text">Work Experience</h4>
                                            </div>
                                        </div>
                                        <br /><br />
                                    </div>
                                    <div className="z-depth-5 content scrollspy" id="__work">
                                        <div className="container row">
                                            <br />
                                            <div className="portfolio-timeline">
                                                <div className="entry timeline-left" data-aos="fade">
                                                    <div className="content" data-aos="fade-right">
                                                        <h5>Microtech Interactive (2017-Current)</h5>
                                                        <p className="flow-text">I'm Senior Software Developer at Microtech Interactive. Here I Develop Apps With Unity3d Platform using C# and Java Script.</p>
                                                        <p className="flow-text">You can <a href="mailto:admin@way2tushar.com?Subject=Hello%20There">email</a> us for more information about us or even for collaborations!</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
            <a className="anchor" id="achievements" href="/#"> </a>
                                    <div className="banner main-banner white-text center">
                                        <br /><br />
                                        <div className="section">
                                            <div className="container">
                                                <h4 className="header col s12 light header-text">Achievements</h4>
                                            </div>
                                        </div>
                                        <br /><br />
                                    </div>
                                    <div className="z-depth-5 content scrollspy" id="__achievements">
                                        <div className="container row">
                                            <br />
                    <div className="col s12">
						<Suspense fallback={<div>Loading...</div>}>
							<img src={awardsPic} alt="way2tushar.com" width="55%" />
						</Suspense>
                                                    <p className="flow-text">
                                                        Robotics competition Runners up in Stamford University Bangladesh, CSE Day 2015
</p>
</div>
                                            </div>
                                        </div>
            <a className="anchor" id="contact" href="/#"> </a>
                                        <div className="banner main-banner white-text center">
                                            <br /><br />
                                            <div className="section">
                                                <div className="container">
                                                    <h4 className="header col s12 light header-text">Contact me</h4>
                                                </div>
                                            </div>
                                            <br /><br />
                                        </div>
                                        <div className="z-depth-5 content scrollspy" id="__contact">

                <div className="container">
                    <div className="row contact-form">
                    <div className="col m10 offset-m1 s12">
                        <form className="col m10 offset-m1 s12" onSubmit={handleSubmit}>
                            <div className="input-field">
                                <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
                                <label htmlFor="name">Name</label>
                            </div>
                            <div className="input-field">
                                <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
                                <label htmlFor="email">Email</label>
                            </div>
                            <div className="input-field">
                                <textarea id="message" className="materialize-textarea" name="message" value={formData.message} onChange={handleChange} required></textarea>
                                <label htmlFor="message">Message</label>
                            </div>
                            <div className="input-field center-align">
                        <ReCAPTCHA
                            sitekey="6LdLrdYnAAAAAIL8jOOUdo8jGtjm_bkaDWksAR5_"
                            onChange={handleRecaptchaChange}
                        />
                    </div>
                    {recaptchaError && <p className="red-text center-align">{recaptchaError}</p>}
                            <button className="btn waves-effect waves-light" type="submit">Send</button>
                        </form>
                        </div>
                    </div>
                    <ToastContainer />
                </div>

                                                
<div className="container">
                                                    <p className="flow-text">Feel free to contact me using any of the platforms below</p>
                                                    <br />
                                                    <div className="contacts-container">
                                                        <a href="mailto:tushar0172@gmail.com?Subject=Hello%20There" className="contact-item">
                                                        <img src={emailIcon} alt="E-mail" />
                                                            <span className="contact-title">E-mail</span>
                                                            <span className="contact-value"><span>tushar0172@gmail.com</span></span>
                                                        </a>
                        <a href="https://www.linkedin.com/in/way2tushar/" target="_blank" rel="noreferrer" className="contact-item">
                                                        <img src={linkedinIcon} alt="Linkedin"></img>
                                                            <span className="contact-title">Linkedin</span>
                                                            <span className="contact-value">MD Nazmul Islam</span>
                        </a>
                        <a href="https://github.com/way2tushar" target="_blank" rel="noreferrer" className="contact-item">
                                                        <img src={githubIcon} alt="Github"></img>
                                                            <span className="contact-title">Github</span>
                                                            <span className="contact-value">MD Nazmul Islam</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
</main>
    )
}

export default MainBody;