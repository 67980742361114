// PrivacyPolicy.js
import React from 'react';
import PrivacyPolicyPage from './Components/PrivacyPolicyPage';

const PrivacyPolicy = () => {
    return (
        <PrivacyPolicyPage /> 
    );
};

export default PrivacyPolicy;
