import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NewTopbar from './Components/NewTopbar';
import Pagebody from './Components/Pagebody';
import MainBody from './Components/MainBody';
import PrivacyPolicy from './PrivacyPolicy'; // Import the PrivacyPolicy component

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Index page */}
        <Route path="/" element={<IndexPage />} />
        {/* Privacy Policy page */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
}

const IndexPage = () => {
  return (
    <>
      <NewTopbar />
      <Pagebody />
      <MainBody />
    </>
  );
}

export default App;
