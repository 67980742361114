import React, { Component } from 'react';
import M from 'materialize-css/dist/js/materialize.min.js';
import "materialize-css/dist/css/materialize.min.css";
import '../CSS/custom.min43a0.css'
import AnchorLink from 'react-anchor-link-smooth-scroll'

class NewTopbar extends Component {

    componentDidMount() {
        let sidenav = document.querySelector('#mobile-nav');
        M.Sidenav.init(sidenav, {});
    }

    render() {
        return (
            <header>
                <span id="top"></span>
                <div className="navbar-fixed">
                    <nav>
                        <div className="container">
                            <div className="nav-wrapper">
                                <a href="#top" className="brand-logo" id="navbar-title">way2tushar</a>
                                <a href="#blank" data-target="mobile-nav" className="sidenav-trigger"
                                ><i className="material-icons">menu</i></a>
                                <ul className="right hide-on-med-and-down">
                                    <li><AnchorLink href="#about">About</AnchorLink></li>
                                    <li><AnchorLink href="#portfolio">Portfolio</AnchorLink></li>
                                    <li><AnchorLink href="#work">Experience</AnchorLink></li>
                                    <li><AnchorLink href="#achievements">Achievements</AnchorLink></li>
                                    <li><AnchorLink href="#contact">Contact</AnchorLink></li>


                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
                <ul className="sidenav sidenav-close" id="mobile-nav">
                    <li><AnchorLink href="#about">About me</AnchorLink></li>
                    <li><AnchorLink href="#portfolio">Portfolio</AnchorLink></li>
                    <li><AnchorLink href="#work">Work Experience</AnchorLink></li>
                    <li><AnchorLink href="#achievements">Achievements</AnchorLink></li>
                    <li><AnchorLink href="#contact">Contact me</AnchorLink></li>


                </ul>
            </header>
        )
    }
}

export default NewTopbar;