import React, { useState } from 'react';

function YouTubeVideo({ videoId }) {
  const [videoLoaded, setVideoLoaded] = useState(false);

  const loadVideo = () => {
    setVideoLoaded(true);
  };

  return (
    <div className="youtube-video">
      {videoLoaded ? (
        <iframe
          title="way2tushar"
          width="100%"
          height="300px"
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0&loop=1&playlist=${videoId}`}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      ) : (
        <div className="video-placeholder" onClick={loadVideo}>
          <img
            src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
            alt="Video Thumbnail"
          />
          <div className="play-button-overlay">
            <div className="play"></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default YouTubeVideo;
